import { View, Text } from "react-native";
import React from "react";
import { LinearGradient } from "expo-linear-gradient";
import { Colors } from "../config";

const PrimaryContainer = ({ children, style, color, borderRadius }) => {
  return (
    <View
      style={[
        {
          width: "90%",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgba(255,255,255,0.01)",
          borderRadius: borderRadius ? borderRadius : 40,
          overflow: "hidden",
        },
        style,
      ]}
    >
      <LinearGradient
        colors={[color ? color : Colors.primaryRgb, "rgba(0,0,0,0.0)"]}
        start={[0.9, -0.99]}
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          zIndex: -1,
        }}
      />
      {children}
    </View>
  );
};

export default PrimaryContainer;
