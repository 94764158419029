import { StyleSheet, Dimensions, Platform } from "react-native";

const windowHeight = Dimensions.get("screen").height;
const windowWidth = Dimensions.get("screen").width;

const styles = StyleSheet.create({
  itemLarge: {
    marginRight: 8,
    marginLeft: 8,
    width: 300,
    minHeight: 300,
  },
  imageLarge: {
    backgroundColor: "transparent",
    width: 200,
    height: 200,
    borderRadius: 1000,
    marginBottom: 20,
    position: "relative",
  },
  content: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 30,
  },
  badgesContainer: {
    position: "absolute",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%",
    paddingHorizontal: 30,
    paddingTop: 10,
    opacity: 0.9,
  },
});

export default styles;
