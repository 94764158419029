import React from "react";
import { View as RNView, StyleSheet, Platform } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

export const View = ({ isSafe, style, children }) => {
  const insets = useSafeAreaInsets();

  if (isSafe) {
    return (
      <RNView
        style={{
          paddingTop: insets.top,
          width: Platform.OS === "web" ? 400 : "90%",
          marginTop: Platform.OS === "web" ? 30 : 5,
          alignSelf: "center",
          ...style,
        }}
      >
        {children}
      </RNView>
    );
  }

  return <RNView style={StyleSheet.flatten(style)}>{children}</RNView>;
};
