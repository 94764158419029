import { View, Text, StyleSheet, Platform, Image } from "react-native";
import React, { useEffect, useState } from "react";
import { getFirestore } from "firebase/firestore";
import { Colors, gStyle } from "../config";

import { doc, getDoc } from "firebase/firestore";
import MainCard from "../components/MainCard";
import PrimaryButton from "../components/PrimaryButton";
import { LinearGradient } from "expo-linear-gradient";
import * as Linking from "expo-linking";

export const RoomScreen = () => {
  const [room, setRoom] = useState(null);
  const [docId, setDocId] = useState(null);
  const [imageLoading, setImageLoading] = useState(true);
  const [errorOpen, setErrorOpen] = useState("");
  const db = getFirestore();

  const getDocRoom = async (docId) => {
    const docRef = doc(db, "rooms", docId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      setRoom(docSnap.data());
    } else {
      console.log("No such document!");
    }
  };

  const handlePressJoin = () => {
    const linkToRoom = `bluntly://path/app?id=${docId}`;
    console.log("link= ", linkToRoom);
    Linking.openURL(linkToRoom)
      .then((res) => console.log("result", res))
      .catch((e) => setErrorOpen(e));
  };

  useEffect(() => {
    Linking.getInitialURL().then((url) => {
      let { path, queryParams } = Linking.parse(url);
      if (queryParams.id) {
        getDocRoom(queryParams.id);
        setDocId(queryParams.id);
      }
    });
  }, []);

  return (
    <View style={styles.container}>
      <LinearGradient
        colors={[room?.imageColor ?? Colors.primaryRgb, "transparent"]}
        start={[0.1, -0.6]}
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          zIndex: -1,
          top: 0,
          opacity: 0.4,
        }}
      />
      <View style={{ height: "100%", justifyContent: "space-around" }}>
        <Image
          style={{
            height: 70,
            width: 300,
            resizeMode: "contain",
            marginTop: 16,
            alignSelf: "center",
          }}
          source={require("../assets/logo.png")}
        />
        {room != null && (
          <MainCard
            imageLoading={imageLoading}
            setImageLoading={() => {
              setImageLoading(false);
            }}
            item={room}
            contentBottom={
              <>
                {room.desc && (
                  <Text
                    style={[
                      gStyle.text14,
                      { width: "80%", textAlign: "center" },
                    ]}
                  >
                    {room.desc}
                  </Text>
                )}
                <View style={gStyle.spacer2} />
                <PrimaryButton
                  onPress={handlePressJoin}
                  title={"Join"}
                  style={{ width: "80%" }}
                />
                {errorOpen != "" && (
                  <Text style={gStyle.text12}>{errorOpen}</Text>
                )}
              </>
            }
          />
        )}
        <View style={styles.downloadContainer}>
          <Text style={[gStyle.textBold18, { textAlign: "center" }]}>
            {`Don't have bluntly? \n`}
          </Text>
          <Image
            style={{
              height: 60,
              width: 200,
              resizeMode: "contain",
              marginTop: 16,
            }}
            source={require("../assets/apple.png")}
          />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: Platform.OS === "web" ? "row" : "column",
    backgroundColor: Colors.bg,
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    // width: "20%",
    // marginTop: 100,
  },
  infoContainer: {
    width: Platform.OS === "web" ? "45%" : "100%",
    alignItems: "center",
    margin: 20,
    padding: 10,
    backgroundColor: Colors.white,
    borderRadius: 10,
  },
  reportsContainer: {
    width: Platform.OS === "web" ? "45%" : "100%",
    margin: 20,
    padding: 10,

    backgroundColor: Colors.white,
    borderRadius: 10,
  },
  downloadContainer: {
    // marginVertical: 20,
    justifyContent: "center",
    alignItems: "center",
  },
});
