import { View, Text } from "react-native";
import React from "react";
import Icon from "../Icon";
import { BlurView } from "expo-blur";
import { Colors } from "../../config";

const Badge = ({ title, iconName }) => {
  return (
    <BlurView
      intensity={100}
      tint="dark"
      style={{
        paddingHorizontal: 10,
        paddingVertical: 5,
        // backgroundColor: colors.primary,
        marginHorizontal: 3,
        borderRadius: 30,
        justifyContent: "center",
        flexDirection: "row",
        overflow: "hidden",
      }}
    >
      {title != "" && (
        <Text style={[{ color: Colors.white }]}>
          {" "}
          //text font
          {title}{" "}
        </Text>
      )}
      {iconName && <Icon name={iconName} size={11} color={Colors.white} />}
    </BlurView>
  );
};

export default Badge;
