import React from "react";
import { View, Text, TouchableOpacity } from "react-native";
import styles from "./style";
import { Colors } from "../../config";

const PrimaryButton = ({
  title,
  onPress,
  style,
  titleStyle,
  disabled,
  isLoading,
}) => {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={[
        styles.container,
        style,
        {
          backgroundColor: disabled
            ? "rgba(255,255,255,0.1)"
            : "rgba(255,255,255,0.9)",
        },
      ]}
    >
      <Text style={[styles.title, titleStyle]}>{title}</Text>
    </TouchableOpacity>
  );
};

export default PrimaryButton;
