import React from "react";
import {
  StyleSheet,
  TouchableOpacity,
  Text,
  View,
  Image,
  ActivityIndicator,
} from "react-native";
import { Colors, gStyle } from "../../config";

import styles from "./style";

import { SquircleView } from "react-native-figma-squircle";
import PrimaryContainer from "../PrimaryContainer";
import Badge from "../Badge";

const MainCard = ({
  item,
  heading = "none",
  onPress,
  withBg,
  contentBottom,
  fullTitle,
  badges,
  style,
  flag,
  onReportFlagPress,
  setImageLoading,
  imageLoading,
}) => {
  const handleItemPress = () => {
    onPress ? onPress() : console.log("onPress Main Card");
  };
  return (
    <TouchableOpacity
      activeOpacity={0.99}
      hitSlop={{ top: 10, left: 10, bottom: 10, right: 10 }}
      onPress={handleItemPress}
    >
      <>
        {badges && (
          <View style={styles.badgesContainer}>
            {badges?.map((el) => {
              return (
                el.title !== "" && (
                  <Badge iconName={el.iconName} title={el.title} />
                )
              );
            })}
          </View>
        )}
        <PrimaryContainer
          color={item.imageColor ?? Colors.primary}
          style={[
            styles.itemLarge,
            { backgroundColor: withBg ? Colors.bg : "rgba(255,255,255,0.01)" },
            style,
          ]}
        >
          <View style={styles.content}>
            <View style={styles.imageLarge}>
              {item.image && (
                <Image
                  source={{
                    uri: item?.image,
                  }}
                  onLoadEnd={setImageLoading}
                  style={styles.imageLarge}
                />
              )}
              {imageLoading && (
                <ActivityIndicator
                  style={{ position: "absolute", left: 100, top: 100 }}
                  color={Colors.primary}
                />
              )}
            </View>
            <View style={[{ alignSelf: "center", marginHorizontal: 32 }]}>
              <Text
                numberOfLines={fullTitle ? 3 : 1}
                style={[gStyle.textBold20, gStyle.mB2]}
              >
                {item.name}
              </Text>
            </View>
            {contentBottom}
          </View>
        </PrimaryContainer>
      </>
    </TouchableOpacity>
  );
};

export default MainCard;
