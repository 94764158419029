import React from "react";
import { TouchableOpacity, View, Text } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import { Colors } from "../config";

const Icon = ({ onPress, style, name, size, color, badge, badgeNum }) => {
  return (
    <>
      <TouchableOpacity
        hitSlop={{ top: 10, left: 10, bottom: 10, right: 10 }}
        onPress={onPress}
        style={style}
      >
        <Ionicons
          name={name ? name : "close"}
          size={size ? size : 34}
          color={color ? color : "white"}
        />
      </TouchableOpacity>
      {badge && (
        <View
          style={{
            backgroundColor: Colors.primary,
            borderRadius: 10,
            position: "absolute",
            top: 3,
            right: -3,
            width: 10,
            height: 10,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* <Text style={[gStyle.text10]}>{badgeNum}</Text> */}
        </View>
      )}
    </>
  );
};

export default Icon;
