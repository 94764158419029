import { StyleSheet, Dimensions } from "react-native";
import { gStyle, Colors } from "../../config";

const styles = StyleSheet.create({
  container: {
    height: 55,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 20,
  },
  title: {
    ...gStyle.textBold18,
    color: Colors.bg,
  },
});

export default styles;
