import React from "react";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { View } from "react-native";
import { RootNavigator } from "./navigation/RootNavigator";
import { AuthenticatedUserProvider } from "./providers";
import {
  useFonts,
  ArchivoBlack_400Regular,
} from "@expo-google-fonts/archivo-black";

import {
  Archivo_400Regular,
  Archivo_500Medium,
} from "@expo-google-fonts/archivo";

const App = () => {
  let [fontsLoaded] = useFonts({
    ArchivoBlack_400Regular,
    Archivo_400Regular,
    Archivo_500Medium,
  });

  if (!fontsLoaded) {
    return <View></View>;
  } else {
    return (
      <AuthenticatedUserProvider>
        <SafeAreaProvider>
          <RootNavigator />
        </SafeAreaProvider>
      </AuthenticatedUserProvider>
    );
  }
};

export default App;
