import React from "react";
import { View, StyleSheet, Platform, Text } from "react-native";
import { Button } from "../components";
import { Colors } from "../config";

export const HomeScreen = ({ navigation }) => {
  return (
    <View style={styles.container}>
      <Button
        borderless
        title="Open"
        onPress={() => navigation.navigate("Room")}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: Platform.OS === "web" ? "row" : "column",
    backgroundColor: Colors.white,
  },
  button: {
    // width: "20%",
    // marginTop: 100,
  },
  infoContainer: {
    width: Platform.OS === "web" ? "45%" : "100%",
    alignItems: "center",
    margin: 20,
    padding: 10,
    backgroundColor: Colors.white,
    borderRadius: 10,
  },
  reportsContainer: {
    width: Platform.OS === "web" ? "45%" : "100%",
    margin: 20,
    padding: 10,

    backgroundColor: Colors.white,
    borderRadius: 10,
  },
});
