export const Colors = {
  orange: "#f57c00",
  blue: "#039be5",
  black: "#222222",
  white: "#ffffff",
  mediumGray: "#6e6869",
  red: "#fc5c65",
  redLight: "rgba(191, 29, 38, 0.2)",
  green: "#00FF00",
  red: "#ff0000",
  primary: "#562efb", //#038C7F is najs allso #04D9C4
  primaryLess: "#b69dfd",
  bg: "#080808",
  primaryRgb: "rgba(86, 46, 251,0.4)",
};
